.profile {
  width: 100%;
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-size: 18px;
  &__heading {
    margin: 0 !important;
  }

  &__show-info {
    padding: 20px 20px 0;
    align-items: center;
    display: flex;
  }

  &__title {
    font-size: 18px;
  }

  &__card {
    margin-bottom: 10px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__info-body {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}
