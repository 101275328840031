.seo {

  &__wrap {
    display: flex;
  flex-direction: row-reverse;
  }

  &__form {
    width: 100%;
  }

  &__textarea {
    height: 555px !important;
    resize: none !important;
  }
}
