.button {
  background-color: inherit;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  padding: 0px 10px;
  margin: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.055);
  }
}
