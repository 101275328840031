
.content-center {
  align-items: center;
  display: flex;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}