@import '@/styles/colors.scss';

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(255,255,255,.7);
  display: block;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__img {
    animation: lds-circle 1000ms infinite linear;
    color: $colorPrimary;
    display: inline-block;
  }
}

@keyframes lds-circle {
  from {
    transform: rotateY(0deg);
  } to {
    transform: rotateY(360deg);
  }
}