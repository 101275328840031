@import "../../styles/colors";

.layout {
  height: 100%;

  &__logo {
    align-items: center;
    box-shadow: 0 0 1px $white;
    color: $colorPrimary;
    display: flex;
    padding: 0 10px;
    margin: 0 10px 10px 0;
    height: 64px;
    width: 100%;

    &--center {
      justify-content: center;
    }

    &-text {
      color: $white;
      padding-left: 15px;
      font-size: 20px;
    }
  }

  &__menu {
    overflow-y: auto;
  }
}
