// Antd color
$colorPrimary: #bc8e5b;


// Any
$white: #FFFFFF;


// the :export directive is the magic sauce for webpack
:export {
  colorPrimary: $colorPrimary;
  white: $white;
}
