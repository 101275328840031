.dropdown-wrapper {
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  h5 {
    color: white;
    margin: 0;
  }
}
