@import "../../../styles/colors";

.logout {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 10px;
    font-size: 18px;
}

.logout__title {
    font-size: 18px;
}
