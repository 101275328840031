.message {
  display: grid;
  gap: 5px;
  padding: 10px;
  border: 1px solid #ffe5e5;
  border-radius: 3px;
  width: 90%;
  font-size: 14px;

  &__title {
    font-size: 16px;
    font-weight: bold;
  }
}
