@import "../../../styles/colors";

.header {
  padding: 0 15px;

  &__icon {
    color: $white;
    font-size: 20px;
  }

  &__logout-btn {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    min-width: 200px;
  }
}
